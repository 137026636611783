import type {AppContext, AppInitialProps, AppProps} from 'next/app';
import 'bootstrap/dist/css/bootstrap.css'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import '../styles/scss/App.scss';
import '../libs/font-awesome/scss/fontawesome.scss';
import '../libs/font-awesome/scss/brands.scss';
import '../libs/font-awesome/scss/duotone.scss';
import '../libs/font-awesome/scss/light.scss';
import '../libs/font-awesome/scss/regular.scss';
import '../libs/font-awesome/scss/sharp-solid.scss';
import '../libs/font-awesome/scss/solid.scss';
import '../libs/font-awesome/scss/thin.scss';
import '../libs/font-awesome/scss/v4-shims.scss';
import i18next from 'i18next';
import {useRouter} from "next/router";
import {wrapper} from "../stores/store";
import apiInternal from "../utils/apiInternal";
import {useEffect} from "react";
import {initializeGoogleTagManager} from "../utils/googleTagManager";
import {initLightboxJS} from "lightbox.js-react";
import {AxiosResponse} from "axios";

let revision: string = '';

const App = ({Component, pageProps}: AppProps) => {
    const router = useRouter();

    useEffect(() => {
        initializeGoogleTagManager(process.env.NEXT_PUBLIC_GTM!);
        initLightboxJS("65FA-FC06-F49C-4302", 'team');

        const handleRouteChange = async (url: string) => {
            const resRevision: AxiosResponse<any> = await apiInternal().post('/api/v2/git/revision');
            if (revision.length && resRevision.data !== revision) {
                console.error('-- revision changed, full page load');
                // Add a query parameter to force a new request
                const newUrl = `${url}?_rev=${resRevision.data}`;
                return location.href = newUrl;
            }
            revision = resRevision.data;
        };

        router.events.on('routeChangeStart', handleRouteChange)

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeStart', handleRouteChange)
        }
    }, [router])

    i18next.init({
        lng: router.locale ?? 'de',
        fallbackLng: 'de',
        supportedLngs: ['de', 'fr', 'en'],
        resources: pageProps.i18n,
    });

    return (
        <Component key={i18next.language} {...pageProps}/>
    );
}

App.getInitialProps = async (context: AppContext): Promise<AppInitialProps> => {
    const locale = context.router.locale;
    const res = context.ctx.res!;

    if (context.router.query.at_gd) {
        const d = new Date();
        d.setFullYear(d.getFullYear() + 1);
        res.setHeader('Set-Cookie', [
            `at_gd=${context.router.query.at_gd}; Expires=${d}; Path=/`
        ])
    }

    const [
        resNavigation,
        resultFooter,
        resultFooterTop,
        resultFooterSide,
        resTeaserAd,
        resI18n,
        resLogo
    ] = await Promise.all([
        apiInternal().post('/api/v2/navigation/main', {locale}),
        apiInternal().post('/api/v2/static/footer', {locale}),
        apiInternal().post('/api/v2/static/footer/top', {locale}),
        apiInternal().post('/api/v2/static/footer/side', {locale}),
        apiInternal().post('/api/v2/teaser/ad', {locale}),
        apiInternal().post('/api/v2/i18n/list'),
        apiInternal().post('/api/v2/layout/logo'),
    ]);

    return {
        pageProps: {
            mainNavigation: resNavigation.data,
            footer: resultFooter.data,
            footerTop: resultFooterTop.data,
            footerSide: resultFooterSide.data,
            teaserAd: resTeaserAd.data,
            i18n: resI18n.data,
            logo: resLogo ? resLogo.data : {}
        },
    }
}


export default wrapper.withRedux(App);
