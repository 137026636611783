import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface SearchState {
  query: string;
  lastQuery: string | null;
  result: any;
  offCanvas: boolean;
  loading: boolean;
}

const initialState: SearchState = {
  query: '',
  lastQuery: null,
  result: null,
  offCanvas: false,
  loading: false
}

export const searchSlice = createSlice({
  name: 'searchState',
  initialState,
  reducers: {
    setQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },

    setLastQuery(state, action: PayloadAction<string>) {
      state.lastQuery = action.payload;
    },

    setResult(state, action: PayloadAction<any>) {
      state.result = action.payload;
    },

    setOffCanvas(state, action: PayloadAction<boolean>) {
      state.offCanvas = action.payload;
    },

    clearSearch(state) {
      state.result = null;
      state.query = '';
    },

    clearResult(state) {
      state.result = null;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    closeSearch(state) {
      state.result = null;
      state.query = '';
      state.loading = false;
      state.offCanvas = false;
    }
  }
})

export const {setQuery, setResult, clearSearch, setOffCanvas, setLoading, closeSearch, setLastQuery} = searchSlice.actions;
export default searchSlice.reducer;
